import {VsfContext} from '~/composables/context';
import {Logger} from '~/helpers/logger';
import {CartItemInput,} from '~/modules/GraphQL/types';
import {cart} from "@vue-storefront/magento-api/lib/api";
import encodeUid from "~/helpers/encodeUid";
import decodeUid from "~/helpers/decodeUid";

/** Params object used to add items to a cart */
export declare type AddProductsToOfflineCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addOfflineItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      customQuery,
    },
  ) => {
    Logger.debug('[Magento]: Add item to offline cart', {
      product,
      quantity,
      currentCart,
    });
    let cartId;
    const apiState = context.$magento.config.state;
    if(JSON.parse(localStorage.getItem('cartData')).cart.items.length > 0){
      let dataId=JSON.parse(localStorage.getItem('cartData')).cart.id;
      cartId=apiState.setCartId(dataId);
    }else{
      cartId = apiState.getCartId();
    }



    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    let data = JSON.parse(localStorage.getItem('cartData'));
    if (!data.cart.id) {
      console.log('cartidData',data)
      data.cart.id = cartId;
    }
    let count = 0;
    let total = 0;

    if (data && data.cart.items.length) {
      let added = false;
      for (let index in data.cart.items) {
        let item = data.cart.items[index];
        if (item.product.sku == product.sku) {
          data.cart.items[index].product.qty += quantity;
          data.cart.items[index].quantity += quantity;
          data.cart.items[index].prices = {
            row_total: { value: data.cart.items[index].product.qty * product.price_range.minimum_price.final_price.value },
            row_total_including_tax: { value: data.cart.items[index].product.qty * product.price_range.minimum_price.final_price.value },
            total_item_discount: { value: 0},
          };
          added = true;
        }
        count += data.cart.items[index].product.qty;
        total += data.cart.items[index].product.qty * data.cart.items[index].product.price_range.minimum_price.final_price.value;
      }
      if (!added) {
        product.qty = quantity;
        // count += quantity;
        // total += product.qty * product.price_range.minimum_price.final_price.value;
        let item = {product: null, prices: {}, uid: '', quantity: null};
        item.product = product;
        item.prices = {
          row_total: { value: product.qty * product.price_range.minimum_price.final_price.value },
          row_total_including_tax: { value: product.qty * product.price_range.minimum_price.final_price.value },
          total_item_discount: { value: 0},
        };
        item.quantity = quantity;

        item.uid = product.uid;
        data.cart.items.push(item);

      }
    } else {
      product.qty = quantity;
      // count += quantity;
      // total += product.qty * product.price_range.minimum_price.final_price.value;
      let item = {product: null, prices: {}, quantity: null, uid: ''};
      item.product = product;
      item.prices = {
        row_total: { value: product.qty * product.price_range.minimum_price.final_price.value },
        row_total_including_tax: { value: product.qty * product.price_range.minimum_price.final_price.value },
        total_item_discount: { value: 0},
      };
      item.quantity = quantity;

       item.uid = product.uid;
      data.cart.items.push(item);
    }
    let totalQty=0;
    let totalOffline=0;
    let qty;
    for(let qtyIndex in data.cart.items){
      qty=data.cart.items[qtyIndex].quantity
      totalQty+=qty;
      totalOffline+=data.cart.items[qtyIndex].quantity * data.cart.items[qtyIndex].product.price_range.minimum_price.final_price.value

    }

    data.cart.total_quantity = totalQty;
    data.cart.prices = {
      applied_taxes: [],
      discounts: null,
      grand_total: {value: totalOffline},
      subtotal_excluding_tax: {value: totalOffline},
      subtotal_including_tax: {value: totalOffline},
    };
    data.cart.email = localStorage.getItem('customer_email');
    data.cart.shipping_addresses = [JSON.parse(localStorage.getItem('shippingDetails'))];
    data.cart.billing_address = [JSON.parse(localStorage.getItem('shippingDetails'))];
    localStorage.setItem('cartData', JSON.stringify(data));

    return data.cart;
  },
};
