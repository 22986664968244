













import { SfImage } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SfImage },
});
