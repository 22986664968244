import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput,
} from '~/modules/GraphQL/types';
import { CustomQuery } from '~/types/core';

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      customQuery ={addProductsToCart: 'addProductsToCart'},
    },
  ) => {
    Logger.debug('[Magento]: Add item to online cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    // let cartId;
    // if(JSON.parse(localStorage.getItem('cartData')).cart.items.length > 0){
    //   cartId=JSON.parse(localStorage.getItem('cartData')).cart.id;
    //   apiState.setCartId(cartId);
    // }else{
    //   cartId=apiState.getCartId();
    // }
    const cartId = apiState.getCartId();
    //console.log('cartIDDDD',cartId);
    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    if (product.__typename === 'SimpleProduct') {
      const simpleCartInput: AddProductsToCartInput = {
        cartId,
        cartItems: [
          {
            quantity,
            sku: product.sku,
          },
        ],
      };

      const simpleProduct = await context.$magento.api.addProductsToCart(simpleCartInput, customQuery as CustomQuery);

      Logger.debug('[Result]:', {data: simpleProduct.data});


      if (simpleProduct.data.addProductsToCart.user_errors.length > 0) {
        throw new Error(String(simpleProduct.data.addProductsToCart.user_errors[0].message));
      }
      let cartData={
        cart:{
          id:simpleProduct.data.addProductsToCart.cart.id,
          items:simpleProduct.data.addProductsToCart.cart.items,
          prices:{
            applied_taxes:[],
            discounts:0,
            grand_total:{
              value:0
            },
            subtotal_excluding_tax:{
              value:0
            },
            subtotal_including_tax:{
              value:0
            },
            total_quantity:0
          }
        }
      }
      localStorage.setItem('cartData',JSON.stringify(cartData));
      // eslint-disable-next-line consistent-return
      return simpleProduct
        .data
        .addProductsToCart
        .cart as unknown as Cart;
    } else if (product.__typename === 'ConfigurableProduct') {
      const selectedOptions = Object.values(productConfiguration as object);

      const configurableCartInput: AddProductsToCartInput = {
        cartId,
        cartItems: [
          {
            quantity,
            sku: product.sku,
            selected_options: selectedOptions,
          },
        ],
      };

      const configurableProduct = await context.$magento.api.addProductsToCart(configurableCartInput, customQuery as CustomQuery);

      Logger.debug('[Result]:', {data: configurableProduct.data});

      if (configurableProduct.data.addProductsToCart.user_errors.length > 0) {
        throw new Error(String(configurableProduct.data.addProductsToCart.user_errors[0].message));
      }
      let cartData = {
        cart: {
          id:  configurableProduct.data.addProductsToCart.cart.id,
          items: configurableProduct.data.addProductsToCart.cart.items,
          prices: {
            applied_taxes: [],
            discounts: 0,
            grand_total: {
              value: 0
            },
            subtotal_excluding_tax: {
              value: 0
            },
            subtotal_including_tax: {
              value: 0
            },
            total_quantity: 0
          }
        }
      }
      localStorage.setItem('cartData', JSON.stringify(cartData));
      // eslint-disable-next-line consistent-return
      return configurableProduct.data
        .addProductsToCart
        .cart as unknown as Cart;
    } else if (product.__typename === 'BundleProduct') {
      const createEnteredOptions = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
        product.bundle_options.map((bundleOption) => ({
          ...bundleOption,
          value: bundleOption.value.toString(),
        }));

      const bundleCartInput: AddProductsToCartInput = {
        cartId,
        cartItems: [
          {
            quantity,
            sku: product.sku,
            entered_options: createEnteredOptions(),
          },
        ],
      };

      const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput, customQuery as CustomQuery);

      Logger.debug('[Result]:', {data: bundleProduct});

      if (bundleProduct.data.addProductsToCart.user_errors.length > 0) {
        throw new Error(String(bundleProduct.data.addProductsToCart.user_errors[0].message));
      }
      let  cartData = {
        cart: {
          id: bundleProduct.data.addProductsToCart.cart.id,
          items: bundleProduct.data.addProductsToCart.cart.items,
          prices: {
            applied_taxes: [],
            discounts: 0,
            grand_total: {
              value: 0
            },
            subtotal_excluding_tax: {
              value: 0
            },
            subtotal_including_tax: {
              value: 0
            },
            total_quantity: 0
          }
        }
      }
      localStorage.setItem('cartData', JSON.stringify(cartData));
      // eslint-disable-next-line consistent-return
      return bundleProduct
        .data
        .addProductsToCart
        .cart as unknown as Cart;
    } else if (product.__typename === 'DownloadableProduct') {
      const downloadableCartInput: AddProductsToCartInput = {
        cartId,
        cartItems: [
          {
            quantity,
            sku: product.sku,
          },
        ],
      };

      const downloadableProduct = await context.$magento.api.addProductsToCart(downloadableCartInput, customQuery as CustomQuery);

      Logger.debug('[Result DownloadableProduct]:', {data: downloadableProduct});

      if (downloadableProduct.data.addProductsToCart.user_errors.length > 0) {
        throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message));
      }

      // eslint-disable-next-line consistent-return
      return downloadableProduct
        .data
        .addProductsToCart
        .cart as unknown as Cart;
    } else if (product.__typename === 'VirtualProduct') {
      var downloadableProduct;
      const virtualCartInput: AddProductsToCartInput = {
        cartId,
        cartItems: [
          {
            quantity,
            sku: product.sku,
          },
        ],
      };
      const virtualProduct = await context.$magento.api.addProductsToCart(virtualCartInput, customQuery as CustomQuery);

      Logger.debug('[Result VirtualProduct]:', {data: virtualProduct});

      if (downloadableProduct.data.addProductsToCart.user_errors.length > 0) {
        throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message));
      }

      // eslint-disable-next-line consistent-return
      return virtualProduct
        .data
        .addProductsToCart
        .cart as unknown as Cart;
    } else {// eslint-disable-next-line no-underscore-dangle
      throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
