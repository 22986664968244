import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables';

export const loadOfflineTotalQtyCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<{}>) => {
    Logger.debug('[Magento]: Load cart total qty offline');
    const data = JSON.parse(localStorage.getItem('cartData'));
   // console.log('totalQty', data.total_quantity)
    return data.total_quantity;
  },
};
