import locale7803b02f from '../../lang/se.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"sv_SE","numberFormats":{"sv_SE":{"currency":{"style":"currency","currency":"SEK","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"sv_SE","file":"se.js","iso":"sv_SE","defaultCurrency":"SEK"}],
  defaultLocale: "sv_SE",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/dfs-pwa/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "https://shipshop.se/",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "US",
  normalizedLocales: [{"code":"sv_SE","file":"se.js","iso":"sv_SE","defaultCurrency":"SEK"}],
  localeCodes: ["sv_SE"],
}

export const localeMessages = {
  'se.js': () => Promise.resolve(locale7803b02f),
}
