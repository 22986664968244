<template>
  <label :for="id + '_button'" :class="{'active': isActive}" class="toggle__button">
<!--    <span v-if="isActive" class="toggle__label">{{ enableText }}</span>-->
    <span  class="toggle__label">Use internet </span>

    <input type="checkbox" :disabled="disabled" :id="id + '_button'" v-model="checkedValue">
    <span class="toggle__switch"></span>
  </label>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    labelEnableText: {
      type: String,
      default: 'On'
    },

    labelDisableText: {
      type: String,
      default: 'Off'
    },
    id: {
      type: String,
      default: 'primary'
    },
    defaultState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentState: this.defaultState
    }
  },
  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState)
    }
  },
  computed: {
    // currentState() {
    //     return this.defaultState;
    // },
    isActive() {
      return this.currentState;
    },
    enableText() {
      return this.labelEnableText;
    },
    disabledText() {
      return this.labelDisableText;
    },
    checkedValue: {
      get() {
        return this.currentState;
      },
      set(newValue) {
        this.currentState = newValue;
        this.$emit('change', newValue);
      }
    }
  }
}
</script>

<style scoped>
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.toggle__button input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.toggle__button .toggle__switch{
  display: inline-block;
  background: #BFCBD9;
  box-shadow: inset 0 0 1px #bfcbd9;
  position: relative;
  margin-left: 10px;
  width: 56px;
  height: 20px;
  border-radius: 17px;
  padding: 3px;
  transition: all .25s cubic-bezier(0, 0, 0.93, 0.95);
}
.toggle__button.active .toggle__switch::before {
  content: 'On';
  font-size: 9px;
  color: black;
  opacity: 1;
  background: none;
  font-weight: 400;
  box-shadow: none;
  margin-left: 8px;
  margin-top: 4px;
}
.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;

  transform: translateX(0);
  transition: all .25s cubic-bezier(0, 0, 0.93, 0.95);
}
.toggle__button .toggle__switch::after {
  background: #4D4D4D;
  box-shadow: 0 0 1px #666;
  width: 18px;
  height: 18px;
  border-radius: 19px;
  padding: 0;

  top: 4px;
  left: 4px;
}
/*.toggle__button .toggle__switch::before {*/
/*  background: #4D4D4D;*/
/*  box-shadow: 0 0 0 3px rgba(0,0,0,0.1);*/
/*  opacity:0;*/
/*}*/
.active .toggle__switch {
  background: white;
  width: 56px;
  height: 20px;
  border-radius: 17px;
  padding: 3px;

}
.active .toggle__switch::after,
.active .toggle__switch::before{
  transform:translateX(40px - 18px);
}
.active .toggle__switch::after {
  left: 35px;
  background: #53B883;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 19px;
  padding: 0;

  box-shadow: 0 0 1px #53b883;
  top: 1px;
}
label.toggle__button {
  display: inline-flex;
  /* align-content: center; */
  flex-wrap: nowrap;
  flex-direction: row;
  margin-left: 15px;
  align-items: center;
}
</style>
