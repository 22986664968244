import { Ref, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type{ CategoryTree } from '~/modules/GraphQL/types';
import type {
  UseCategoryErrors,
  UseCategoryInterface,
  UseCategoryParamsInput,
} from './useCategory';
import {entries} from "idb-keyval";

/**
 * @public
 *
 * Allows loading categories from Magento API. It
 * is commonly used in navigation menus, and provides the load function and
 * refs for the categories, loading and error.
 *
 * See the {@link UseCategoryInterface} for a list of methods and values available in this composable.
 *
 * @remarks
 *
 * Under the hood, it calls the following Server Middleware API method:
 *
 * - {@link @vue-storefront/magento-api#categoryList} for loading category list;
 *
 * It is currently used in:
 *
 * - `components/AppHeader.vue`
 *
 * - `components/MobileMenuSidebar.vue`
 *
 * @example
 *
 * Load categories on client side using the `onMounted` Composition API hook:
 *
 * ```vue
 * <template>
 *   <div v-if="loading">
 *     Loading categories…
 *   </div>
 *   <div v-else-if="error.load">
 *     Error: {{ error.load.message }}
 *   </div>
 *   <div v-else>
 *     <!-- Display 'categories' -->
 *   </div>
 * </template>
 *
 * <script>
 * import { onMounted } from '@nuxtjs/composition-api';
 * import { useCategory } from '~/modules/catalog/category/composables/useCategory';
 *
 * export default {
 *   setup() {
 *     const { categories, error, load, loading } = useCategory();
 *
 *     onMounted(async () => {
 *       await load({ pageSize: 10 });
 *     });
 *
 *     return {
 *       error,
 *       loading,
 *       categories,
 *     };
 *   },
 * };
 * </script>
 * ```
 */
export function useCategory(): UseCategoryInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseCategoryErrors> = ref({
    load: null,
  });
  const categories: Ref<Array<CategoryTree>> = ref(null);

  const load = async (params: UseCategoryParamsInput) => {
    Logger.debug('useCategory/load', params);

    try {
      loading.value = true;
      const isOffline = () => {

        if(typeof window != 'undefined') {
          if(localStorage.getItem('isOffline') == 'true') {
            return true;
          }
        }
        return false;
      }
      if(!isOffline()){
        const { data } = await app.context.$vsf.$magento.api.categoryList(params, params?.customQuery ?? null);
        Logger.debug('[Result]:', { data });
        categories.value = data.categories.items;
        error.value.load = null;
        //console.log('categoryList', JSON.stringify(data))
      }else {
        let data = {
          categories: {
            items:[{
            children: [{
              is_anchor: 1,
              name: '',
              position: 0,
              product_count: 0,
              uid: '',
              url_path: '',
              url_suffix: '.html',
              include_in_menu: 1,
            }]
            }]
          }
        }
        entries().then(async function (entries) {
          for await (const data1 of entries) {
            if (data1[0].toString().startsWith('categoryTree')) {
              Object.keys(data1[1]["Mg=="].children["NTEz"].children).map(async function (level3Key) {
                Object.keys(data1[1]["Mg=="].children["NTEz"].children[level3Key].children).map(async function (level4Key) {
                  let level4Cat = data1[1]["Mg=="].children["NTEz"].children[level3Key];
                  // if (window.location.pathname == ('/c/' + level4Cat.url_path + level4Cat.url_suffix)) {
                  //   console.log('categoryonpage', level4Cat)
                  //   let ids = level4Cat.path.split('/');
                    await new Promise((resolve, reject) => setTimeout(resolve, 3000));
                  // data.categories.item.id = ids[ids.length - 1];
                    data.categories.items[0].children[0].name=level4Cat.name;
                    data.categories.items[0].children[0].position=level4Cat.product_count;
                    data.categories.items[0].children[0].uid=level4Cat.uid;
                    data.categories.items[0].children[0].url_path=level4Cat.url_path ;

                    // routeData.value.redirect_code = 0;
                    // routeData.value.relative_url = (level4Cat.url_path + level4Cat.url_suffix);
                    // routeData.value.uid = level4Cat.uid;
                    // routeData.value["__typename"] = "CategoryTree";


                  // }
                })
              })
            }
          }
        });

      console.log('categorytree',JSON.stringify(data));
      }
    } catch (err) {
      error.value.load = err;
      Logger.error('useCategory/load', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    loading,
    error,
    categories,
  };
}

export * from './useCategory';

export default useCategory;
