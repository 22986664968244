import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { ComposableFunctionArgs, Countries } from '~/composables/types';
import { Maybe, Country } from '~/modules/GraphQL/types';
import { UseCountrySearchInterface } from './useCountrySearch';
import {country} from "@vue-storefront/magento-api/lib/api";

/**
 * Allows fetching a list of countries or a single country by ID
 *
 * See the {@link UseCountrySearchInterface} for a list of methods and values available in this composable.
 */
export function useCountrySearch(): UseCountrySearchInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref({
    search: null,
    load: null,
  });
  function isOffline(){

    if(typeof window != 'undefined') {
      if(localStorage.getItem('isOffline') == 'true') {
        return true;
      }
    }
    return false;

  }
  const search = async (params: ComposableFunctionArgs<{ id: string }>): Promise<Maybe<Country>> => {
    let results = null;
    if (!isOffline()) {
    try {

      loading.value = true;

      Logger.debug('[Magento]: Search country information based on', { params });

        const {data} = await app.$vsf.$magento.api.country(params.id, params?.customQuery ?? null);

        Logger.debug('[Result]:', {data});

        results = data?.country ?? null;
      Logger.debug('[Resultcountry]:', results);
      localStorage.setItem('countries',JSON.stringify(data));
        error.value.search = null;

    } catch (err) {
      error.value.search = err;
      Logger.error('useCountrySearch/search', err);
    } finally {
      loading.value = false;
    }
    }else{
      try {

        loading.value = true;

        Logger.debug('[Magento]: Search country information based on', { params });
        const data= localStorage.getItem('countries');
     //   const {data} = await app.$vsf.$magento.api.country(params.id, params?.customQuery ?? null);

        Logger.debug('[Result]:', JSON.parse(data));

        results =  JSON.parse(data)?.country ?? null;
        Logger.debug('[Resultcountry]:',  JSON.parse(data)?.country);


        error.value.search = null;

      } catch (err) {
        error.value.search = err;
        Logger.error('useCountrySearch/search', err);
      } finally {
        loading.value = false;
      }
    }
    return results;
  };

  const load = async (): Promise<Array<Countries>> => {
    let results = [];
    if (!isOffline()) {
    try {

      loading.value = true;

      Logger.debug('[Magento]: Load available countries on store');


        const {data} = await app.$vsf.$magento.api.countries();

        Logger.debug('[Result]:', {data});

        results = data?.countries ?? [];
      Logger.debug('[Resultcountry]:', results);
      localStorage.setItem('countries',JSON.stringify(data));
        error.value.search = null;

    } catch (err) {
      error.value.search = err;
      Logger.error('useCountrySearch/load', err);
    } finally {
      loading.value = false;
    }
    }else{
      try {

        loading.value = true;

        Logger.debug('[Magento]: Load available countries on store');
        const data= JSON.parse(localStorage.getItem('countries'));

        //const {data} = await app.$vsf.$magento.api.countries();

        Logger.debug('[Result]:', {data});


         results =  data?.countries ?? [];
        Logger.debug('[Resultcountry]:',  data?.countries );

        //     localStorage.setItem('countries',JSON.stringify(data));
        error.value.search = null;

      } catch (err) {
        error.value.search = err;
        Logger.error('useCountrySearch/load', err);
      } finally {
        loading.value = false;
      }
    }
    return results;
  };

  return {
    load,
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export * from './useCountrySearch';
export default useCountrySearch;
