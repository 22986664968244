import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables';

export const loadTotalQtyCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<{}>) => {
    Logger.debug('[Magento]: Load cart total qty');

    const apiState = context.$magento.config.state;
    let data;
    const isOffline = () => {
      if (typeof window != 'undefined') {
        if (localStorage.getItem('isOffline') == 'true') {
          return true;
        }
      }
      return false;
    }

    if(!isOffline()){
    if (apiState.getCartId()) {


      data = await context.$magento.api.cartTotalQty(apiState.getCartId(), params?.customQuery ?? null);

      return data;
    }
      }else{
          data  = JSON.parse(localStorage.getItem('cartData')).cart.items.length;

        return data;
      }




    return data?.cart?.total_quantity ?? 0;
  },
};
