import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const updateOfflineItemQtyCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    product,
    quantity,
    customQuery = { updateOfflineCartItems: 'updateOfflineCartItems' },
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };
    const apiState = context.$magento.config.state;
    product = product.product;
    let data = JSON.parse(localStorage.getItem('cartData'));
    const cartId = apiState.getCartId();
    if (!data.cart.id) {
      data.cart.id = cartId;
    }
    let count = 0;
    let total = 0;
    quantity = parseInt(quantity);
    if (data && data.cart.items.length) {
      for (let index in data.cart.items) {
        let item = data.cart.items[index];
        if (item.product.sku == product.sku) {
          data.cart.items[index].product.qty = quantity;
          data.cart.items[index].quantity = quantity;
          data.cart.items[index].prices = {
            row_total: { value: data.cart.items[index].product.qty * product.price_range.minimum_price.final_price.value },
            row_total_including_tax: { value: data.cart.items[index].product.qty * product.price_range.minimum_price.final_price.value },
            total_item_discount: { value: 0},
          };
        }
        count += data.cart.items[index].product.qty;
        total += data.cart.items[index].product.qty * data.cart.items[index].product.price_range.minimum_price.final_price.value;
      }
      let totalQty=0;
      let totalOffline=0;
      let qty=0;
      for(let qtyIndex in data.cart.items){
        qty=data.cart.items[qtyIndex].quantity
        totalQty+=qty;
        totalOffline+=data.cart.items[qtyIndex].quantity * data.cart.items[qtyIndex].product.price_range.minimum_price.final_price.value

      }
      data.cart.total_quantity = totalQty;
      data.cart.prices = {
        applied_taxes: [],
        discounts: null,
        grand_total: {value: totalOffline},
        subtotal_excluding_tax: {value: totalOffline},
        subtotal_including_tax: {value: totalOffline},
      };
      localStorage.setItem('cartData', JSON.stringify(data));
    }

    Logger.debug('[Result]:', { data });

    return data.cart;
  },
};
