import { ProductInterface, Wishlist } from '~/modules/GraphQL/types';
import { productMatch } from '~/modules/wishlist/helpers/productMatch';

export const findItemOnWishlist = (currentWishlist: Wishlist, product: ProductInterface) => {

  const isOffline = () => {

    if(typeof window != 'undefined') {
      if(localStorage.getItem('isOffline') == 'true') {
        return true;
      }
    }
    return false;
  }
  if (isOffline()) {
    const wishlist=JSON.parse(localStorage.getItem('wishlistData'));
    //console.log('wishlist list Find', JSON.stringify(wishlist.items_v2.items.find((item) => productMatch(item.product, product))));
    return wishlist
      ?.items_v2
      ?.items?.find((item) => productMatch(item.product, product));
  }else{
    const wishlist: Wishlist = Array.isArray(currentWishlist) ? currentWishlist[0] : currentWishlist;

    return wishlist
      ?.items_v2
      ?.items?.find((item) => productMatch(item.product, product));
  }

};
