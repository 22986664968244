
<template>
  <SfTopBar class="topbar">
    <template #left>
      <SfButton class="sf-button--text" @click="showModalInfo = true">
        {{ $t('Help & FAQs') }}
      </SfButton>
      <HelpFaq  v-show="showModalInfo"  @close-modal="showModalInfo = false"/>
<!--      <template v-if="isAuthenticated">-->
<!--      <div>-->
<!--        <toggle  :default-state	="statuschecked"-->
<!--          v-on:change="changeStatus" />-->
<!--      </div>-->
<!--      </template>-->
    </template>

    <template #center>
      <template v-if="isAuthenticated">

        <template v-if="downloading">
          <span class="downloading">Downloading Offline data, Please wait...<br>Remember - Dont close your browser between offline sessions</span>
        </template>
        <template v-if="loadingOnline">
          <span class="downloading">Syncing data for online mode, Please wait...</span>
        </template>

        <template v-else>
          <template v-if="offline">
            <span class="offline">You are Offline</span>
          </template>
          <template v-else>
            <span class="online">You are Online</span>
          </template>
          <span style="cursor:pointer;" v-if="hasInternet" @click="changeStatus()">{{statusText}}</span>
        </template>
        <div class="question-mark">

          <i class="question-mark" @click="showModal = true"  >
            <svg width="24" height="24" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><path d="M 500 0C 224 0 0 224 0 500C 0 776 224 1000 500 1000C 776 1000 1000 776 1000 500C 1000 224 776 0 500 0C 500 0 500 0 500 0 M 501 191C 626 191 690 275 690 375C 690 475 639 483 595 513C 573 525 558 553 559 575C 559 591 554 602 541 601C 541 601 460 601 460 601C 446 601 436 581 436 570C 436 503 441 488 476 454C 512 421 566 408 567 373C 566 344 549 308 495 306C 463 303 445 314 411 361C 400 373 384 382 372 373C 372 373 318 333 318 333C 309 323 303 307 312 293C 362 218 401 191 501 191C 501 191 501 191 501 191M 500 625C 541 625 575 659 575 700C 576 742 540 776 500 775C 457 775 426 739 425 700C 425 659 459 625 500 625C 500 625 500 625 500 625"/></svg>
          </i>

        </div>
        <InfoPop  v-show="showModal"  @close-modal="showModal = false"/>
        <OfflinePopup v-show="showPopup" />
        <ThankYou v-show="showThank"/>
      </template>
    </template>
    <template #right>

      <!--
      /**
       * The currency switch is commented, until the Core package
       * enables the switch of currency without returning to the browser one with i18n
       */
        <CurrencySelector />
      -->
      <LocaleSelector />
    </template>
  </SfTopBar>
</template>

<script>

import {Logger} from "@/helpers/logger";
import { SfButton, SfTopBar } from '@storefront-ui/vue';
// import CurrencySelector from './CurrencySelector';
import LocaleSelector from '../LocaleSelector';
import { get, set } from 'idb-keyval';
import {useUser} from "~/modules/customer/composables/useUser";
import toggle from "~/components/toggle";
import InfoPop from "~/components/InfoPop.vue";
import HelpFaq from "~/components/HelpFaq.vue";

import OfflinePopup from "~/components/OfflinePopup.vue";
import {useContext, useFetch} from "@nuxtjs/composition-api";
import useShipping from "@/modules/checkout/composables/useShipping";
import usePaymentProvider from "@/modules/checkout/composables/usePaymentProvider";
import {
  AvailableShippingMethod,
  Cart,
  RemoveItemFromCartInput,
  ShippingMethodInput,
  StoreConfig
} from "~/modules/GraphQL/types";
import {addItemCommand, AddProductsToCartInput} from "~/modules/checkout/composables/useCart/commands/addItemCommand";
import  {useCart} from "~/modules/checkout/composables/useCart"
import {CustomQuery} from "~/types/core";
import axios from 'axios';
import StoreConfigGql from "~/plugins/query/StoreConfig.gql";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import {findItemOnWishlist} from "~/modules/wishlist/helpers/findItemOnWishlist";
import {useWishlistStore} from "~/modules/wishlist/store/wishlistStore";
import {forEach} from "lodash-es";
import ThankYou from "~/modules/checkout/pages/Checkout/ThankYou.vue";

export default {
  components: {
    ThankYou,
    HelpFaq,
    SfTopBar,
    SfButton,
    LocaleSelector,
    InfoPop,
    OfflinePopup
  },
  setup() {
    const { isAuthenticated, user, load: loadUser } = useUser();

    const {
      load: loadShipping,
      save: saveShipping,
      loading: isShippingLoading,
    } = useShipping();
    useFetch(async () => {
      await loadUser({
        customQuery: {
          customer: 'customer',
        },
      });
    });
    return {
      isAuthenticated,
      user,
      saveShipping
    }
  },

  mounted() {
    if(localStorage.getItem('checkDatabase') == null){
      localStorage.setItem('isOffline','false');
      indexedDB.deleteDatabase('keyval-store')

    }
    setInterval(this.checkInternetConnection, 10000)
    if(localStorage.getItem('isOffline') == 'true') {
      //window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });
      // this.statuschecked = false;
      this.offline = true;
    } else {
      // this.statuschecked = true;
      this.offline = false;
    }
  },
  data() {
    return {
      showModal: false,
      showModalInfo:false,
      showPopup:false,
      showThank:false,
      // statuschecked:false,
      offline: false,
      hasInternet: true,
      downloading: false,
      loadingOnline: false
    }
  },
  props: ['isUser'],
  computed: {
    statusText() {
      if(this.offline === true) {
        // this.statuschecked = false;
        return 'Go Online';
      } else {
        // this.statuschecked =true;
        return 'Go Offline';
      }
    }
  },
  methods: {
    checkInternetConnection() {
      this.hasInternet = window.navigator.onLine;
    },
    async changeStatus() {
      if (!this.offline)
      {
        localStorage.setItem('customer_number', this.user.vsm_cst_act_nr);
        localStorage.setItem('customer_email', this.user.email);
        localStorage.setItem('shippingDetails', JSON.stringify(this.user.addresses[0]));
        localStorage.setItem('billingDetails', JSON.stringify(this.user.addresses[0]));

        let storeConfig={
          storeConfig:{
            default_display_currency_code:"SEK",
            default_title:"Magento Commerce",
            header_logo_src:"",
            locale:"sv_SE",
            logo_alt:null,
            logo_height:null,
            logo_width:null,
            store_code:"sv_SE",
            store_name:"Default Store View",
            __typename:"StoreConfig"
          }
        }
        localStorage.setItem('storeConfig',JSON.stringify(storeConfig));
        let shippingDetails = {
          apartment            : undefined,
          city                 : this.user.addresses[0].city,
          country_code         : this.user.addresses[0].country_code,
          carrier_code         :this.user.addresses[0].carrier_code,
          customerAddressId    : this.user.addresses[0].id,
          firstname            : this.user.addresses[0].firstname,
          lastname             : this.user.addresses[0].lastname,
          postcode             : this.user.addresses[0].postcode,
          region               : this.user.addresses[0].region.region_code,
          save_in_address_book : false,
          street               : this.user.addresses[0].street[0],
          telephone            : this.user.addresses[0].telephone,
        };
        let shippingMethods = [{

            amount:{
              currency:'SEK',
              value:0,
              __typename:"Money"
            },
            available:true,
            carrier_code: "freeshipping",
            carrier_title:"Special arrangement",
            error_message:"",
            method_code:"freeshipping",
            method_title:"",
            price_excl_tax:{
              currency:'SEK',
              value:0,
              __typename:"Money"
            },
            price_incl_tax:{
              currency:'SEK',
              value:0,
              __typename:"Money"
            },
            __typename:"AvailableShippingMethod"

        }];

        localStorage.setItem('availableShippingMethods', JSON.stringify(shippingMethods));
      } else {
        this.loadingOnline = true;

        const {data, errors} = await this.$vsf.$magento.api.customerCart();
       // const {dataWishlist, wishlisterrors} = await this.$vsf.$magento.api.wishlist();
       // const { dataWishlist } = await this.$vsf.$magento.api.wishlist();
        const wishlistStore = useWishlistStore();
        const loadedWishlist =JSON.parse(localStorage.getItem('wishlistDataOnline'));

        let item;
        let quantity;
        let sku;
        let productCart = JSON.parse(localStorage.getItem('cartData'));
        let wishlistCart = JSON.parse(localStorage.getItem('wishlistData'));
        if(wishlistCart.items_v2.items.length >= 0){
          for(let index in loadedWishlist.items_v2.items){
            //const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, loadedWishlist.items_v2.items[index].product);

            const { data } = await this.$vsf.$magento.api.removeProductsFromWishlist({
              id: '0',
              items: [loadedWishlist.items_v2.items[index].id],
            });
            wishlistStore.$patch((state) => {
              state.wishlist = data?.removeProductsFromWishlist?.wishlist ?? {};
            });
           // console.log('product in wishlist cart online', JSON.stringify(loadedWishlist));
          }
          for(let indexOffline in wishlistCart.items_v2.items){
           // console.log('product in wishlist cart offline', wishlistCart.items_v2.items[indexOffline].product)
            wishlistCart.items_v2.items[indexOffline].product.__typename='WishlistItemInput';
         //   const itemOnWishlist = findItemOnWishlist( wishlistCart,  wishlistCart.items_v2.items[indexOffline].product);

            const {data} = await this.$vsf.$magento.api.addProductToWishList({
              id: '0',
              items: [{
                sku: wishlistCart.items_v2.items[indexOffline].product.sku,
                quantity: 1,
              }],
            });

            wishlistStore.$patch((state) => {
              state.wishlist = data?.addProductsToWishlist?.wishlist ?? {};
            });
          }
        }

        if (productCart.cart.items.length >= 0) {
          // let cartId = productCart.cart.id;
          const apiState = this.$vsf.$magento.config.state;
          const cartId = apiState.getCartId();
          for(let indexOnline in data.customerCart.items){
            const removeItemParams={
              cart_id: data.customerCart.id,
              cart_item_uid: data.customerCart.items[indexOnline].uid,
            };
            await this.$vsf.$magento.api.removeItemFromCart(removeItemParams);
          }

          for (let index in productCart.cart.items) {
            item = productCart.cart.items[index];
            quantity = item.quantity;
            sku = item.product.sku;

                const simpleCartInput = {
                  cartId,
                  cartItems: [
                    {
                      quantity,
                      sku: sku,
                    },
                  ],
                };

                await this.$vsf.$magento.api.addProductsToCart(simpleCartInput);
            }
          }
        this.loadingOnline = false;
      }
      var vsfCustomerId;
      var match = document.cookie.match(new RegExp('(^| )' + 'vsf-customer' + '=([^;]+)'));
      if (match){
        vsfCustomerId = (match[2]);
      }
      function wait(ms){
        var start = new Date().getTime();
        var end = start;
        while(end < start + ms) {
          end = new Date().getTime();
        }
      }
      const registerServiceWorker = async () => {
        if ("serviceWorker" in navigator) {
          try {
            const registration = await navigator.serviceWorker.register("/service-worker.js", {
              scope: "/",
            });
            if (registration.installing) {
              console.log("Service worker installing");
            } else if (registration.waiting) {
              console.log("Service worker installed");

            } else if (registration.active) {
              console.log("Service worker active");
            }
          } catch (error) {
            console.error(`Registration failed with ${error}`);
          }
        }
      };

      const offlineCacheNew = () => {
        //await setTimeout(2000);
        // Select the node that will be observed for mutations
        const targetNode = document.getElementById('__layout');

        // Options for the observer (which mutations to observe)
        const config = {childList: true, subtree: true };
        let check =[];
        for(let i = 0; i <= 15; i++)
        {
          check[i] = false;

        }
        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              // console.log(check);

              if (!check[0]  && document.querySelector('div.sf-header__icons > button:nth-child(2)')) {
                // setTimeout(document.querySelector('a.sf-header__logo.nuxt-link-active').click(),3000);
                document.querySelector('div.sf-header__icons > button:nth-child(2)').click();
                //console.log(document.querySelector('div.sf-header__icons > button:nth-child(2)'))
                document.querySelector('#search:nth-child(1)').click();

                console.log('check[0]')
                check[0] = true
              }
              if (check[0] && !check[1] && document.querySelector('div.sf-header__icons > button:nth-child(1)')) {
                setTimeout(document.querySelector('div.sf-header__icons > button:nth-child(1)').click(),3000);
               check[1] = true;
                //check[0] = true;

                console.log('check[1]')
              }

              if (check[1] && !check[2] && document.querySelector('.sf-header-navigation-item__link')) {
               setTimeout(function(){document.querySelector('.sf-header-navigation-item__link').click();},5000)
              //  document.querySelector('div > div:nth-child(4) > div > div > header > div.sf-header__actions > div.sf-input.sf-search-bar.sf-header__search').click();
                  this.showThank=true;
                //console.log(document.querySelector('.sf-header-navigation-item__link'))
                console.log('check[2]')
                check[2] = true;
              }
              // if (check[1] && !check[2] && document.querySelector('#wishlist > div > aside > div.sf-sidebar__top > div > button')) {
              //   document.querySelector('#wishlist > div > aside > div.sf-sidebar__top > div > button').click()
              //   //console.log(document.querySelector('#wishlist > div > aside > div.sf-sidebar__top > div > button'))
              //   console.log('check[3]')
              //
              //   check[2] = true;
              // }
              if (check[2] && !check[3] && document.querySelector('#category > div > div.sidebar.column > div > div > div > ul > li:nth-child(1) > div > div > button')) {
                setTimeout(function(){document.querySelector('#category > div > div.sidebar.column > div > div > div > ul > li:nth-child(1) > div > div > button').click()},7000);
                //console.log(document.querySelector('#category > div > div.sidebar.column > div > div > div > ul > li:nth-child(1) > div > div > button'))
                console.log('check[3]')

                check[3] = true;
              }
              if (check[3] && !check[4] && document.querySelector('#category > div > div.sidebar.column > div > div > div > ul > li:nth-child(1) > div > div > div > div > button:nth-child(1) > a')) {
                document.querySelector('#category > div > div.sidebar.column > div > div > div > ul > li:nth-child(1) > div > div > div > div > button:nth-child(1) > a').click()
                //console.log(document.querySelector('#category > div > div.sidebar.column > div > div > div > ul > li:nth-child(1) > div > div > div > div > button:nth-child(1) > a'))
                console.log('check[4]')

                check[4] = true;
              }
              if (check[4] && !check[5] && document.querySelector('#category > div > div.main.section.column > div.products > div.list-layout > div:nth-child(1) > div.sf-product-card-horizontal__image-wrapper > a')) {
                document.querySelector('#category > div > div.main.section.column > div.products > div.list-layout > div:nth-child(1) > div.sf-product-card-horizontal__image-wrapper > a').click();
               // console.log(document.querySelector('#category > div > div.main.section.column > div.products > div.list-layout > div:nth-child(1) > div.sf-product-card-horizontal__image-wrapper > a'))
                console.log('check[5]')

                check[5] = true;
              }
              if (check[5] && !check[6] && document.querySelector('.product__info .sf-heading__title.h3')) {
                localStorage.setItem('Product added name',document.querySelector('.product__info .sf-heading__title.h3').innerHTML);
               // console.log(document.querySelector('.product__info .sf-heading__title.h3'))
                console.log('check[6]')

                check[6] = true;
              }
              if (check[6] && !check[7] && document.querySelector('#product > div > div.product > div.product__info > div:nth-child(4) > div.sf-add-to-cart.product__add-to-cart > button') && !check[9] && document.querySelector('div.sf-header__icons > button:nth-child(3)')) {
                if(JSON.parse(localStorage.getItem('cartData')).cart.items == ''){
                 document.querySelector('#product > div > div.product > div.product__info > div:nth-child(4) > div.sf-add-to-cart.product__add-to-cart > button').click();
                  console.log('check[7]')

                  check[7] = true
                }else{
                  console.log('check[71]')

                  check[7]=true
                }
              }

              if (check[7] && !check[8]  && document.querySelector('div.sf-header__icons > button:nth-child(3)')) {
                console.log('check[8]')

                document.querySelector('div.sf-header__icons > button:nth-child(3)').click();
                //document.querySelector('#cart > div > aside > div.sf-sidebar__bottom > div > a > button').click();
                // setTimeout(
                //   function() {document.querySelector('#cart > div > aside > div.sf-sidebar__bottom > div > a > button').click();
                //     check[9] = true
                //   },4000)
                check[8] =true;
              }

              if (check[8] && !check[9] && document.querySelector('#cart > div > aside > div.sf-sidebar__bottom > div > a > button')) {
                console.log('check[9]')

                document.querySelector('#cart > div > aside > div.sf-sidebar__bottom > div > a > button').click();
                check[9] = true;
              }
              if (check[9] && !check[10] && document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(1) > span > form > div.form > div > button')) {
                setTimeout(
                  function() {  document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(1) > span > form > div.form > div > button').click()},3000);
                console.log('check[10]')

                check[10] = true;
              }
              if (check[10] && !check[11] && document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(1) > span > form > div.form > div > button')) {
                console.log('check[11]')

                    document.querySelector('#__layout').click();
                // setTimeout(
                //   function() { document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(1) > span > form > div.form > div > button').click();},4000);


                check[11] = true;
              }
              if (check[11] && !check[12] ) {
                console.log('check[12]')

                //  setTimeout(document.querySelector('#cart > div > aside > div.close-icon').click(), 2000)
                setTimeout(
                  function() { document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(1) > span > form > div.shipping-provider > div.form > div.form__action > button').click()},6000);


                check[12] = true;
              }
              if (check[12] && !check[13] && document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(2) > span > form > div.form > div > button')) {
                console.log('check[13]')

                setTimeout(
                  function() {
                    document.querySelector('#checkout > div > div.checkout__main > div > div.sf-steps__content > div:nth-child(2) > span > form > div.form > div > button').click()


                    check[13] = true
                  }, 2000)
              }
              if (check[13] && !check[14] && document.querySelector('div.sf-header__icons > button:nth-child(3)')) {
                document.querySelector('div.sf-header__icons > button:nth-child(3)').click();
                console.log('check[14]')

                check[14] = true;
              }
              if (check[14] && !check[15] && document.querySelector('.sf-collected-product__title-wraper')) {
                if(document.querySelector('.sf-collected-product__title-wraper').innerText === localStorage.getItem('Product added name').trim()){
                  let divParent=document.querySelector('.sf-collected-product__title-wraper').parentNode.parentNode.parentElement.classList[0];
                  document.querySelector('.'+divParent+' .sf-collected-product__remove:first-child').click()
                  setTimeout(
                    function() { document.querySelector('#cart > div > aside > div.sf-sidebar__content > div.notifications > div > div:nth-child(2) > div.button-wrap > button.sf-button_remove_item.sf-button').click();
                      check[15] = true;
                      console.log('check[15]')
                      localStorage.setItem('checkDatabase','1')

                    },3000);
                  //document.querySelector('#cart > div > aside > div.close-icon').click();
                  this.showThank=false;
                  window.location.href = '/';


                }else{
                  document.querySelector('#cart > div > aside > div.sf-sidebar__bottom > div > a > button').click();
                  console.log('check[16]')
                  localStorage.setItem('checkDatabase','1')
                  window.location.href = '/';
                  check[15]=true;


                }

                //
                // if(localStorage.getItem('isOffline')===true) {
                //
                //   // document.querySelector('#cart > div > aside > div.close-icon').click();
                //
                //   window.location.href = '/';
                // }
              }

            }
          }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);

        if(localStorage.getItem('isOffline') === true && localStorage.getItem('checkDatabase') === 1){
          this.showPopup=false;

        }

      };

      const url = 'https://dfs-graphql.dahlquist.nu/graphql.php?customerId='+vsfCustomerId
      // console.log(url)
      if(this.offline === true) {
        localStorage.setItem('isOffline', 'false');

        this.offline = false;
      } else {
        document.querySelector('.topbar').style.zIndex='3';
        this.downloading = true;
        this.showPopup=true;
        let that = this;
        get('categoryTree').then(function (result) {
          if (typeof result == 'undefined') {
            // console.log(vsfCustomerId)
            fetch('https://dfs-graphql.dahlquist.nu/graphql.php?customerId='+vsfCustomerId, {
              method: 'get',
              //headers: { 'Content-Type': 'application/json' }
            })
              .then(res => res.json())
              .then(function(res)
            {
             registerServiceWorker();

              that.loadLocalData(res);
              document.querySelector('a.sf-header__logo').click();
              offlineCacheNew();
            });
            } else {
            setTimeout(function() {
              observer.disconnect();

            }, 12000);
            that.setOffline();
            this.showThank=false;
            that.downloading = false;


          }
        });
       // document.querySelector('.sf-header__logo-image img').click();

      }
    },
    async loadLocalData(data){
      let urls = [];
      set('categoryTree', data.categoryTree);
      for(let record in data.products) {
        urls.push(data.products[record].image.url);
        // urls.push('/Bilder/Main_banner_1.png');
        // urls.push('/Bilder/Main_banner_2.png');
        // urls.push('/Bilder/Main_banner_3.JPG');
        // urls.push('/Bilder/Main_banner_4.JPG');

        set('prod_'+data.products[record].sku, data.products[record]);
      }
      // let index=JSON.stringify(JSON.parse(localStorage.getItem('cartData')).cart.items).length;
      // let product=JSON.parse(localStorage.getItem('cartData')).cart.items[index-1];
      // console.log('this is the product',index)
      for(let i = 0; i < urls.length; i++)
      {
        setTimeout(function(that) {
          let img = new Image();
          img.src = urls[i];
          if (i == urls.length - 1) {
            that.setOffline();
            document.querySelector('.topbar').style.zIndex='2';


            // setTimeout(function() {
            //   window.location.href='/'
            // }, 1000);
          }
        }, i * 20, this)
      }


      for(let record in data.categoryProducts){
        set('cat_'+record, data.categoryProducts[record]);
      }

    },
    setOffline() {
      localStorage.setItem('isOffline', 'true');

      // this.statuschecked = false;
      this.offline = true;
      this.downloading = false;
      this.showThank=false;
      // window.href.location='/'

    },
  }
};

</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  z-index: 2;
  &__button {
    margin: 0 0 0 var(--spacer-xs);
  }
}

.offline {
  background-color: #f23c3c;
  color: white;
  padding: 2px 10px;
  margin-right: 10px;
}

.online {
  background-color: #5ece7b;
  color: white;
  padding: 2px 10px;
  margin-right: 10px;
}

.downloading {
  background-color: #323c97;
  color: white;
  padding: 2px 10px;
  margin-right: 10px;
}
.question-mark svg {
  fill: gray;
  width: 20px;
  height: 20px;
}
</style>
