import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import useApi from '~/composables/useApi';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { Products } from '~/modules/GraphQL/types';
import getFacetDataQuery from './getFacetData.gql';
import type {
  UseFacetInterface, UseFacetErrors, UseFacetSearchResult, FacetSearchParams,
} from './useFacet';
import {useUser} from "~/modules/customer/composables/useUser";
import {entries} from "idb-keyval";

/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
export function useFacet(): UseFacetInterface {
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const error = ref<UseFacetErrors>({
    search: null,
  });
  const {isAuthenticated} = useUser();
  const isOffline = () => {
    if (!isAuthenticated.value) {
      return false;
    }

    if (typeof window != 'undefined') {
      if (localStorage.getItem('isOffline') == 'true') {
        return true;
      }
    }
    return false;
  }
  const defaultItemsPerPage = 20;
  const search = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,

        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInput(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };
      let data1={
        products: {
          items: [],
          total_count:0,
          page_info:{}
        }
      };


      if (isOffline()) {

        await new Promise((resolve, reject) => setTimeout(resolve, 100))
        let categoryUid = await productSearchParams.filter.category_uid.in[0];
        let results = [];
        data1 = {products: {items: [], total_count: 0, page_info: {} } };
        entries().then(async function (entries) {
          for await (const products of entries) {
            if(products[0].toString().startsWith('prod_')) {
              for await (let category of products[1].categories) {
                if (category.uid == categoryUid) {
                  products[1].__typename = 'SimpleProduct';
                  results.push(products[1]);

                }

              }

            }
          }
          await new Promise((resolve, reject) => setTimeout(resolve, 100));
        });
        await new Promise((resolve, reject) => setTimeout(resolve, 2000));
        if(results.length > 0) {
          data1.products.items = results;
          data1.products.total_count = 10;
          data1.products.page_info = {
            current_page: 1,
            page_size: 12,
            total_pages: 1,
          };
        } else {
          data1.products.total_count = 0;
        }
      }


      if(isOffline()){
        const products = data1?.products ?? null;
        result.value.data = {
          items: products?.items ?? [],
          total: products?.total_count,
          availableSortingOptions: sortingOptions,
          perPageOptions,
          itemsPerPage: pageSize,
        };
      }else{
        const { data } = await query<{ products: Products }>(getFacetDataQuery, productSearchParams);
        const productsonline = data?.products ?? null;
        result.value.data = {
          items: productsonline?.items ?? [],
          total: productsonline?.total_count,
          availableSortingOptions: sortingOptions,
          perPageOptions,
          itemsPerPage: pageSize,
        };
      }




      // Logger.debug('[Result]:', { products });
      //
      //
      // if(productsonline){
      //
      // }

      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useFacet';
export default useFacet;
