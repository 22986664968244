<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <h2>Help & FAQs</h2>
      <div class="text-help">
        <p>Welcome to ShipShop, a unique webshop managed by Sweden's largest ship supplier - DFS.
</p><p>
          On here you will be able to browse through over a thousand different products, carefully selected by our experienced purchasing department.
          The idea of this assortment is to cover most of your day-to-day needs on board a vessel, this way you can get all your consumables, workwear, tools, spare parts and whatever else you might need in a single delivery.
          Using state of the art technology you are also able to use ShipShop in "Offline-mode" when you are logged in!
</p><p>
          To place orders directly through ShipShop you need to sign in.
          Don't have an account? Contact us and we will make sure to set one up for you!</p>

        <a href="mailto:order@dfs-ab.se"> order@dfs-ab.se</a>

         <p> If you have any questions or would like to place an order, please look through the FAQ's below or contact us directly for more information!</p>
      </div>

<div class="help-faq">
    <h3>FAQ</h3>
    <div class="tabs">
      <div class="tab">
        <button class="accordion">Can I use ShipShop without logging in?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg></button>
        <div class="accordion-content">
         <p>You can browse our entire assortment without logging in but if you want to place an order through ShipShop you need to be logged in.</p>
          <p>  Please contact us directly if you want to set up an account!</p>
        </div>
      </div>
      <div class="tab">
        <button class="accordion">Can I place an order without an account?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg></button>
        <div class="accordion-content">
        <p>You can use the SKU-numbers of the articles you are interested in and send us a request: <a href="mailto:order@dfs-ab.se"> order@dfs-ab.se</a>.</p>
         <p> To place an order online you will need to set up an account!</p>
        </div>
      </div>
      <div class="tab">
        <button class="accordion">How does the "Offline-mode" work exactly?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg></button>
        <div class="accordion-content">
          <p>In short, when you log in and press "Offline" you are basically able to use the entire site without an internet connection!</p>
          <p>  When you activate "Offline-mode" (you will of course need to be online when doing so) the entire website will be stored in your browser, any changes you made before going offline will be there and anything you do while offline will still be there when going online again!</p>
         <p>   This makes it possible for you to go offline while in port and then continue to use the site, wherever you go next, without an internet connection!  </p>
        </div>
      </div>
      <div class="tab">
        <button class="accordion">Can i place orders while in "Offline-mode"?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg></button>
        <div class="accordion-content">
          <p>Yes, you can extract an order in "Offline-mode" and send it (e-mail) to us.</p>
           <p> Otherwise, if you have an internet connection again, you can just go online and send in your order that you created while offline.</p>
        </div>
      </div>
      <div class="tab">
        <button class="accordion">How will my order be delivered?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg></button>
        <div class="accordion-content">
          <p>If the order is supposed to be delivered on to a vessel (ship) the customer has to make arrangements with our delivery department.</p>

            <p>Contact: <a href="mailto:delivery@dfs-ab.se">  delivery@dfs-ab.se</a> </p>

          <p>  In all other circumstances (delivery to a warehouse for instance) you get to choose the delivery address yourself. Your addresses can be seen and edited under your account information..</p>
          <p> To place an order online you will need to set up an account!</p>
        </div>
      </div>
    </div>
</div>
  </div>
    <div class="close" @click="$emit('close-modal')">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
    </div>
  </div>
</template>
<script>
import { SfButton, SfTopBar } from '@storefront-ui/vue';
import LocaleSelector from '/components/LocaleSelector';
import { get, set } from 'idb-keyval';
import {useUser} from "~/modules/customer/composables/useUser";


export default {
  mounted(){
    const accordionBtns = document.querySelectorAll(".accordion");

    accordionBtns.forEach((accordion) => {
      accordion.onclick = function () {
        this.classList.toggle("is-open");

        let content = this.nextElementSibling;
        console.log(content);

        if (content.style.maxHeight) {
          //this is if the accordion is open
          content.style.maxHeight = null;
        } else {
          //if the accordion is currently closed
          content.style.maxHeight = content.scrollHeight + "px";
          console.log(content.style.maxHeight);
        }
      };
    });

  }
}


</script>

<style scoped>
/*.modal .text-help{*/
/*  height: 300px;*/
/*  overflow-x: hidden;*/
/*  padding: 30px;*/
/*}*/
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow-x: hidden;
}
.modal h2{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  min-height: 100px;
  border-bottom: 1px solid #64758e;
  font-size: 20px;
}
.modal {

  background-color: white;
  height:fit-content;
  width: 60%;
  margin-top: 10%;
}
.close {
  margin: 13% 0 0 -45px;

  cursor: pointer;
  z-index: 2;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button{
  /* background-color: #ac003e; */
  width: 150px;
  background: #018937;
  height: 40px;
  color: white;
  /* border-color: #018937; */
  font-size: 14px;
  /* border-radius: 16px; */
  /* margin-top: 50px; */
  box-shadow: none!important;
  border: none;
  /* margin: auto; */
  /* text-align: center; */
}
svg.bi.bi-x {
  fill: white;
  background: green;
  border-radius: 50px;
  width: 20px;
  height: 20px;
}

button.accordion {
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  text-align: left;
  padding: 9px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s linear;
}



button.accordion:hover,
button.accordion.is-open {
  background-color: #ddd;
}

.accordion-content {
  background-color: white;
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.text-help, .help-faq {
  padding: 30px;
}
.tab {
  margin-top: 5px;
  margin-bottom: 5px;
}
.modal a{
  color: green;
  font-size: 16px;
}
.modal svg.bi.bi-caret-down-fill {
  float: right;
  fill:green;
}
@media(max-width:500px){
  .modal{
    width:90%;

  }
  button.accordion{
    height: auto;
  }
}
</style>
