<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <h2>OFFLINE MODE</h2>
      <div class="text">
        <p>This catalog uses state of the art technology to provide full and easy access, even when you are offline.

     If your browser does not support this site, we suggest downloading Google Chrome.</p>

        <p>If you want to use ShipShop in Offline-mode, simply press "Go Offline" and wait until it indicates that you are offline (approx. 1 minute).
In simple terms: When you activate Offline-mode the site downloads all relevant data, no more data (internet) is required or used when you continue using the site.</p>

        <p>Pretty much all actions available with an internet connection is also available when you have activated Offline-mode.
          If you, for instance, add products to the cart that will be synced once you go online again so no actions are lost in the transfer from offline to online (or vice versa).
          You can even send your orders while in Offline-mode, this does however require that you still have enough internet access to send e-mails.</p>


<!--        <template v-if="downloading">-->
<!--          <span class="downloading">Downloading Offline data, Please wait...</span>-->
<!--        </template>-->
<!--        <template v-else>-->

<!--          <button @click="changeStatus()">{{statusText}}</button>-->
<!--        </template>-->
      </div>


    </div>
    <div class="close" @click="$emit('close-modal')">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
    </div>
  </div>
</template>
<script>
import { SfButton, SfTopBar } from '@storefront-ui/vue';
import LocaleSelector from '/components/LocaleSelector';
import { get, set } from 'idb-keyval';
import {useUser} from "~/modules/customer/composables/useUser";


export default {
}
// components: {SfTopBar,
// SfButton,
//   LocaleSelector
//
// },
// setup() {
// },
//
// mounted() {
// if(localStorage.getItem('isOffline') == 'true') {
// // this.statuschecked = false;
// this.offline = true;
// } else {
// // this.statuschecked = true;
// this.offline = false;
// }
// },
// data() {
// return {
// offline: false,
// downloading: false
// }
// },
// props: ['isUser'],
// computed: {
// statusText() {
// if(this.offline === true) {
//
// return 'Go Online';
// } else {
//
// return 'Go Offline';
// }
// }
// },
// methods: {
// checkStausModal() {
// this.showModal = true;
//
// },
// changeStatus() {
// var vsfCustomerId;
// var match = document.cookie.match(new RegExp('(^| )' + 'vsf-customer' + '=([^;]+)'));
// if (match){
// vsfCustomerId = (match[2]);
// }
// const url = 'https://dfs-graphql.dahlquist.nu/graphql.php?customerId='+vsfCustomerId
//
// if(this.offline === true) {
// localStorage.setItem('isOffline', 'false');
// this.offline = false;
// } else {
//
// this.downloading = true;
//
// let that = this;
//
// get('categoryTree').then(function (result) {
// if (typeof result == 'undefined') {
// // console.log(vsfCustomerId)
// fetch('https://dfs-graphql.dahlquist.nu/graphql.php?customerId='+vsfCustomerId, {
// method: 'get',
//
// })
// .then(res => res.json())
// .then(res =>
// that.loadLocalData(res)
// );
// } else {
// that.setOffline();
// }
// });
//
// }
//   this.$forceUpdate()
//
// },
// loadLocalData(data){
//
// set('categoryTree', data.categoryTree);
//
// for(let record in data.products){
// set('prod_'+data.products[record].sku, data.products[record]);
// }
//
// for(let record in data.categoryProducts){
// set('cat_'+record, data.categoryProducts[record]);
// }
//
// this.setOffline();
//
// } ,
// setOffline() {
// localStorage.setItem('isOffline', 'true');
//
// this.offline = true;
// this.downloading = false;
// },
//
// }
//
// };

</script>

<style scoped>
.modal .text{
  height: 300px;
  overflow-x: hidden;
  padding: 30px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow-x: hidden;
}
.modal h2{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  min-height: 100px;
  border-bottom: 1px solid #64758e;
  font-size: 20px;
}
.modal {

  background-color: white;
  height:fit-content;
  width: 500px;
  margin-top: 10%;
}
.close {
  margin: 13% 0 0 -45px;

  cursor: pointer;
  z-index: 2;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button{
  /* background-color: #ac003e; */
  width: 150px;
  background: #018937;
  height: 40px;
  color: white;
  /* border-color: #018937; */
  font-size: 14px;
  /* border-radius: 16px; */
  /* margin-top: 50px; */
  box-shadow: none!important;
  border: none;
  /* margin: auto; */
  /* text-align: center; */
}
svg.bi.bi-x {
  fill: white;
  background: green;
  border-radius: 50px;
  width: 20px;
  height: 20px;
}
</style>
