import { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import useOfflineCart from "~/modules/checkout/composables/useOfflineCart";
import {cart} from "@vue-storefront/magento-api/lib/api";




export const loadOfflineCartCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<{
    realCart?: boolean;

  }>) => {
    const apiState = context.$magento.config.state;
    Logger.debug('[Magento Storefront]: Loading offline Cart');
    const customerToken = apiState.getCustomerToken();

    const makeid = async (length: number) => {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    const createRealCart = async (): Promise<string> => {
      Logger.debug('[Magento Storefront]: useCart.load.createNewOfflineCart');
      let data;
      apiState.setCartId();
      if(JSON.parse(localStorage.getItem('cartData')).cart.items.length > 0){
        data=JSON.parse(localStorage.getItem('cartData')).cart.id;
        apiState.setCartId(data);

        localStorage.setItem('cartData', data);
      }else{
        data= {createEmptyCart: await makeid(32)};
        console.log('cartid',apiState.getCartId());
        apiState.setCartId(data.createEmptyCart);
        let dataCart = {
          cart: {
            id:data.createEmptyCart,
            items: [],
            total_quantity: 0,
            prices: {
              applied_taxes: [],
              discounts: null,
              grand_total: {value: 0 },
              subtotal_excluding_tax: {value: 0 },
              subtotal_including_tax: {value: 0 },
            },
          }
        };
        localStorage.setItem('cartData', JSON.stringify(dataCart));
      }

        return data.createEmptyCart;



    };
    let data = JSON.parse(localStorage.getItem('cartData'));
     const getCartData = async (id: string) => {
      Logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', data.cart.id);


      let errors = [];
      Logger.debug('[Result cartData]:', { data });

      if (!data?.cart) {
        if (errors?.length) {
          throw errors[0];

        } else {
          data = {
            cart: {
              id:data.cart.id,
              items: [],
              total_quantity: 0,
              prices: {
                applied_taxes: [],
                discounts: null,
                grand_total: {value: 0 },
                subtotal_excluding_tax: {value: 0 },
                subtotal_including_tax: {value: 0 },
              },
            }
          };
          localStorage.setItem('cartData', JSON.stringify(data));
        }
      }

//     data.cart.items = data.cart?.items.filter(Boolean);
      return data.cart as unknown as Cart;
    };

    const getCart = async (cartId?: string) => {

      if(JSON.parse(localStorage.getItem('cartData')).cart.items.length > 0){
        cartId=JSON.parse(localStorage.getItem('cartData')).cart.id;
        apiState.setCartId(cartId);
      }else{
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }
      // if (!cartId) {
      //   // eslint-disable-next-line no-param-reassign
      //
      // }


      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        const data = JSON.parse(localStorage.getItem('cartData'));
        let errors = [];
        Logger.debug('[Result]:', { data, errors });

        if (!data?.cart && errors?.length) {
          throw errors[0];
        }
        // apiState.setCartId(data.customerCart.id);
        data.cart.items = data.cart.items.filter(Boolean);
        return data.cart as unknown as Cart;
      } catch(err) {
        Logger.debug('loadOfflineCartCommand error', err);
        // apiState.setCustomerToken();
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it

      let cartId;
      if(JSON.parse(localStorage.getItem('cartData')).cart.items.length > 0){
        let dataId=JSON.parse(localStorage.getItem('cartData')).cart.id;
        cartId=apiState.setCartId(dataId);
      }else{
        cartId = apiState.getCartId();
      }
      console.log('cartData',getCart(cartId))
      return await getCart(cartId);
    } catch {
      apiState.setCartId();
      return await getCart();
    }
  },
};
