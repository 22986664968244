import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery } from '~/types/core';
import {removeItem} from "~/helpers/asyncLocalStorage";

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    { currentCart, product, customQuery },
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };
    let dataOffline = JSON.parse(localStorage.getItem('cartData'));
    const { data } = await context.$magento.api.removeItemFromCart(removeItemParams, customQuery as CustomQuery);

    Logger.debug('[Result]:', { data });

    let count = 0;
    let total = 0;

    let removeIndex = -1;
    if (dataOffline && dataOffline.cart.items.length) {
      for (let index in dataOffline.cart.items) {
        let cartItem = dataOffline.cart.items[index];

        if (item.uid == cartItem.uid) {

          removeIndex = parseInt(index);


        } else {
          count += parseInt(dataOffline.cart.items[index].product.qty);
          total += dataOffline.cart.items[index].product.qty * dataOffline.cart.items[index].product.price_range.minimum_price.final_price.value;
        }
      }

      dataOffline.cart.items.splice(removeIndex, 1);
      let totalQty=0;
      let totalOffline=0;
      let qty=0;
      for(let qtyIndex in dataOffline.cart.items){
        qty=dataOffline.cart.items[qtyIndex].quantity
        totalQty+=qty;
        totalOffline+=dataOffline.cart.items[qtyIndex].quantity * dataOffline.cart.items[qtyIndex].product.price_range.minimum_price.final_price.value

      }
      dataOffline.cart.total_quantity = totalQty;
      dataOffline.cart.prices = {
        applied_taxes: [],
        discounts: null,
        grand_total: {value: totalOffline},
        subtotal_excluding_tax: {value: totalOffline},
        subtotal_including_tax: {value: totalOffline},
      };
      localStorage.setItem('cartData', JSON.stringify(dataOffline));
    }
    // eslint-disable-next-line consistent-return
    return data
      .removeItemFromCart
      .cart as unknown as Cart;
  },
};
