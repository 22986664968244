import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist';
import { Logger } from '~/helpers/logger';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { Wishlist } from '~/modules/GraphQL/types';
import type {
  UseWishlistAddItemParams,
  UseWishlistErrors,
  UseOfflineWishlistInterface,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistAfterAddingWishlistItemToCartParams,
} from '~/modules/wishlist/composables/useOfflineWishlist/useOfflineWishlist';
import { useUiNotification } from '~/composables';
import {useProductsWithCommonProductCardProps} from "~/modules/catalog/category/components/views/useProductsWithCommonCardProps";

/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link useOfflineWishlistInterface} for a list of methods and values available in this composable.
 */
export function useOfflineWishlist(): UseOfflineWishlistInterface {
  const wishlistStore = useWishlistStore();
  const { app } = useContext();
  const { send: sendNotification } = useUiNotification();
  const loading = ref(false);
  const error = ref<UseWishlistErrors>({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
  });

  const load = async (params?: UseWishlistLoadParams) => {
    Logger.debug('useOfflineWishlist/load');

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useOfflineWishlist.load params->', params);
      const apiState = app.$vsf.$magento.config.state;

      let data = JSON.parse(localStorage.getItem('wishlistData'));
      if (!data) {
        data = {
              id: 11,
              items_count: 0,
              items_v2: {
                __typename: "WishlistItems",
                items: [],
                page_info: {current_page: 1, page_size: 10, total_pages: 1, __typename: "SearchResultPageInfo"}
              },
              __typename: "Wishlist"
        };

        localStorage.setItem('wishlistData', JSON.stringify(data));
      }
      Logger.debug('[Result load offline wishlist]:', { data });
      const loadedWishlist = data ?? [];
      Logger.debug('loadedWishlist', loadedWishlist);
      //Logger.debug('wishlistStore.wishlist before', wishlistStore.wishlist);
      if (loadedWishlist) {
        wishlistStore.wishlist = loadedWishlist;

        //location.reload();
       // Logger.debug('wishlistStore.wishlist after1', wishlistStore.wishlist);
      }
 //     Logger.debug('wishlistStore.wishlist after2', wishlistStore.wishlist);

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useOfflineWishlist/load', err);
    } finally {
      loading.value = false;
    }

    return wishlistStore.wishlist;
  };

  const isInWishlist = ({ product }: UseWishlistIsInWishlistParams) => {
    Logger.debug('useOfflineWishlist/isInWishlist', product);
    let data = JSON.parse(localStorage.getItem('wishlistData'));
    if (!data) {
      load({});
    }
    data = JSON.parse(localStorage.getItem('wishlistData'));

    const wishlistProduct = findItemOnWishlist(data, product);

    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  };

  const setWishlist = (newWishlist: Wishlist) => {
    wishlistStore.wishlist = newWishlist;
    Logger.debug('useOfflineWishlist/setWishlist', newWishlist);
  };

  const removeItem = async ({ product, customQuery }: UseWishlistRemoveItemParams) => {
    Logger.debug('useOfflineWishlist/removeItem', product);

    try {
      //let count = 0;
      loading.value = true;
      let data = JSON.parse(localStorage.getItem('wishlistData'));
      let removeIndex = -1;
      if (data && data.items_v2.items.length) {
        for (let index in data.items_v2.items) {
          let wishlistItem = data.items_v2.items[index].product;
          if (product.uid == wishlistItem.uid) {
            removeIndex = parseInt(index);
          }
        }
        data.items_v2.items.splice(removeIndex, 1);
        data.items_count =  data.items_v2.items.length;
        localStorage.setItem('wishlistData', JSON.stringify(data));

        load({});
      }
      Logger.debug('[Result]:', { data });
      error.value.removeItem = null;
      // wishlistStore.$patch((state) => {
      //   state.wishlist = data;
      // });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useOfflineWishlist/removeItem', err);
    } finally {
      loading.value = false;
    }
    isInWishlist({ product });
    console.log('PRODUCTS NAME',product.name)
    let addtoWishlistBtn= document.querySelector('button[aria-label=\'Add to wishlist '+ product.name +'\'] span');
    let removeWishlistBtn= document.querySelector('button[aria-label=\'Remove from wishlist '+ product.name +'\'] span');
    let productWishlistIcon= document.querySelector('.product__additional-actions .add-to-wishlist span');
    let productWislistBtn=document.querySelector('.product__additional-actions .add-to-wishlist button');
    if((typeof (addtoWishlistBtn) != 'undefined'  && (addtoWishlistBtn)!=null) ) {
      addtoWishlistBtn.innerHTML = '' +
        '<svg viewBox="0 0 24 24" preserveAspectRatio="none" class="sf-icon-path"> ' +
        '<defs class=""><linearGradient id="linearGradient-10814" x1="0" y1="0" x2="1" y2="0">' +
        '<stop offset="1" stop-color="var(--icon-color)"></stop> ' +
        '<stop offset="0" stop-color="var(--icon-color-negative, var(--c-gray-variant))"></stop></linearGradient></defs>' +
        '<path d="M 9.988281 18.625 L 1.601562 10.238281 C 0.640625 9.273438 0.109375 ' +
        '7.996094 0.109375 6.632812 C 0.109375 5.273438 0.640625 3.992188 1.601562 3.027344 C 2.566406 2.066406 ' +
        '3.847656 1.535156 5.207031 1.535156 C 6.570312 1.535156 7.851562 2.066406 8.8125 3.027344 L 9.988281 4.203125 ' +
        'L 11.164062 3.027344 C 12.128906 2.066406 13.410156 1.535156 14.769531 1.535156 C 16.132812 1.535156 17.410156 ' +
        '2.066406 18.375 3.027344 C 19.335938 3.992188 19.867188 5.273438 19.867188 6.632812 C 19.867188 7.996094 19.335938' +
        ' 9.273438 18.375 10.238281 Z M 5.207031 2.414062 C 4.082031 2.414062 3.019531 2.851562 2.222656 3.648438 C 1.425781 ' +
        '4.445312 0.988281 5.507812 0.988281 6.632812 C 0.988281 7.761719 1.425781 8.820312 2.222656 9.617188 L 9.988281 17.382812 L 17.753906 9.617188 C ' +
        '18.550781 8.820312 18.988281 7.761719 18.988281 6.632812 C 18.988281 5.507812 18.550781 4.445312 17.753906 3.648438 C 16.957031 2.851562 15.898438 2.414062' +
        ' 14.769531 2.414062 C 13.644531 2.414062 12.582031 2.851562 11.785156 3.648438 L 9.988281 5.445312 L 8.191406 3.648438 C 7.394531 2.851562 6.335938 2.414062 5.207031 ' +
        '2.414062 Z M 5.207031 2.414062"></path></svg>';
    }else if((typeof (removeWishlistBtn) != 'undefined'  && (removeWishlistBtn)!=null)){
      removeWishlistBtn.innerHTML = '' +
        '<svg viewBox="0 0 24 24" preserveAspectRatio="none" class="sf-icon-path"> ' +
        '<defs class=""><linearGradient id="linearGradient-10814" x1="0" y1="0" x2="1" y2="0">' +
        '<stop offset="1" stop-color="var(--icon-color)"></stop> ' +
        '<stop offset="0" stop-color="var(--icon-color-negative, var(--c-gray-variant))"></stop></linearGradient></defs>' +
        '<path d="M 9.988281 18.625 L 1.601562 10.238281 C 0.640625 9.273438 0.109375 ' +
        '7.996094 0.109375 6.632812 C 0.109375 5.273438 0.640625 3.992188 1.601562 3.027344 C 2.566406 2.066406 ' +
        '3.847656 1.535156 5.207031 1.535156 C 6.570312 1.535156 7.851562 2.066406 8.8125 3.027344 L 9.988281 4.203125 ' +
        'L 11.164062 3.027344 C 12.128906 2.066406 13.410156 1.535156 14.769531 1.535156 C 16.132812 1.535156 17.410156 ' +
        '2.066406 18.375 3.027344 C 19.335938 3.992188 19.867188 5.273438 19.867188 6.632812 C 19.867188 7.996094 19.335938' +
        ' 9.273438 18.375 10.238281 Z M 5.207031 2.414062 C 4.082031 2.414062 3.019531 2.851562 2.222656 3.648438 C 1.425781 ' +
        '4.445312 0.988281 5.507812 0.988281 6.632812 C 0.988281 7.761719 1.425781 8.820312 2.222656 9.617188 L 9.988281 17.382812 L 17.753906 9.617188 C ' +
        '18.550781 8.820312 18.988281 7.761719 18.988281 6.632812 C 18.988281 5.507812 18.550781 4.445312 17.753906 3.648438 C 16.957031 2.851562 15.898438 2.414062' +
        ' 14.769531 2.414062 C 13.644531 2.414062 12.582031 2.851562 11.785156 3.648438 L 9.988281 5.445312 L 8.191406 3.648438 C 7.394531 2.851562 6.335938 2.414062 5.207031 ' +
        '2.414062 Z M 5.207031 2.414062"></path></svg>';
    }else if((typeof (productWishlistIcon) != 'undefined'  && (productWishlistIcon)!=null)){
      productWishlistIcon.innerHTML= '<svg  width="32" height="32"> ' +

        '<path d="M 9.988281 18.625 L 1.601562 10.238281 C 0.640625 9.273438 0.109375 ' +
        '7.996094 0.109375 6.632812 C 0.109375 5.273438 0.640625 3.992188 1.601562 3.027344 C 2.566406 2.066406 ' +
        '3.847656 1.535156 5.207031 1.535156 C 6.570312 1.535156 7.851562 2.066406 8.8125 3.027344 L 9.988281 4.203125 ' +
        'L 11.164062 3.027344 C 12.128906 2.066406 13.410156 1.535156 14.769531 1.535156 C 16.132812 1.535156 17.410156 ' +
        '2.066406 18.375 3.027344 C 19.335938 3.992188 19.867188 5.273438 19.867188 6.632812 C 19.867188 7.996094 19.335938' +
        ' 9.273438 18.375 10.238281 Z M 5.207031 2.414062 C 4.082031 2.414062 3.019531 2.851562 2.222656 3.648438 C 1.425781 ' +
        '4.445312 0.988281 5.507812 0.988281 6.632812 C 0.988281 7.761719 1.425781 8.820312 2.222656 9.617188 L 9.988281 17.382812 L 17.753906 9.617188 C ' +
        '18.550781 8.820312 18.988281 7.761719 18.988281 6.632812 C 18.988281 5.507812 18.550781 4.445312 17.753906 3.648438 C 16.957031 2.851562 15.898438 2.414062' +
        ' 14.769531 2.414062 C 13.644531 2.414062 12.582031 2.851562 11.785156 3.648438 L 9.988281 5.445312 L 8.191406 3.648438 C 7.394531 2.851562 6.335938 2.414062 5.207031 ' +
        '2.414062 Z M 5.207031 2.414062" style="fill:#018937;"></path></svg>';
      productWislistBtn.innerHTML='Add to wishlist'
    }


  };

  const loadItemsCount = async (): Promise<number | null> => {
    Logger.debug('useOfflineWishlist/wishlistItemsCount');
    const apiState = app.context.$vsf.$magento.config.state;
    let itemsCount : number | null = null;

    try {
      loading.value = true;
      error.value.loadItemsCount = null;
      let data = JSON.parse(localStorage.getItem('wishlistData'));
      if (!data) {
        await load({});
      }
      Logger.debug('useOfflineWishlist/wishlistItemsCount [Result]:', { data });
      const loadedWishlist = data ?? [];
      Logger.debug('useOfflineWishlist/wishlistItemsCount result', loadedWishlist);
      itemsCount = loadedWishlist?.items_count ?? 0;
      console.log('items count',itemsCount);
      wishlistStore.$patch((state) => {
        state.wishlist.items_count = itemsCount;
      });
    } catch (err) {
      error.value.loadItemsCount = err;
      Logger.error('useOfflineWishlist/wishlistItemsCount', err);
    } finally {
      loading.value = false;
    }

    return itemsCount;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({ product, customQuery }: UseWishlistAddItemParams) => {
    Logger.debug('useOfflineWishlist/addItem', product);

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useOfflineWishlist.addItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery,
      });

      if (!wishlistStore.wishlist) {
        await load({});
      }

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);

      if (itemOnWishlist) {
        return;
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      let data = JSON.parse(localStorage.getItem('wishlistData'));
      if (!data) {
        await load({});
      }
      // let count = 0;
      if (data && data.items_v2.items.length) {
        let added = false;
        for (let index in data.items_v2.items) {
          let item = data.items_v2.items[index];
          if (item.uid == product.uid) {
            added = true;
          }
          // count++;
        }
        if (!added) {
          // count++;
          let item = {product: null, uid: null, quantity: 1, description: null, id: '67', __typename: "SimpleWishlistItem"};
          item.product = product;
          item.uid = product.uid;
          data.items_v2.items.push(item);

        }
      } else {
        // count++;
        let item = {product: null, uid: null, quantity: 1, description: null, id: '67', __typename: "SimpleWishlistItem"};

        item.product = product;
        item.uid = product.uid;
        data.items_v2.items.push(item);
      }

        data.items_count =  data.items_v2.items.length;

      wishlistStore.$patch((state) => {
        state.wishlist = data?.addProductsToWishlist?.wishlist ?? {};
      });
      localStorage.setItem('wishlistData', JSON.stringify(data));
      isInWishlist({ product });
      await load({});
      let addtoWishlistBtn= document.querySelector('button[aria-label=\'Add to wishlist '+ product.name +'\'] span');
      let removeWishlistBtn= document.querySelector('button[aria-label=\'Remove from wishlist '+ product.name +'\'] span');
      let productWishlistIcon= document.querySelector('.product__additional-actions .add-to-wishlist span');
      let productWislistBtn=document.querySelector('.product__additional-actions .add-to-wishlist button');
      if(typeof (addtoWishlistBtn) != 'undefined'  && (addtoWishlistBtn)!=null){
        addtoWishlistBtn.innerHTML='' +
          '<svg viewBox="0 0 24 24" preserveAspectRatio="none" class="sf-icon-path"> ' +
          '<defs class=""><linearGradient id="linearGradient-10814" x1="0" y1="0" x2="1" y2="0">' +
          '<stop offset="1" stop-color="var(--icon-color)"></stop> ' +
          '<stop offset="0" stop-color="var(--icon-color-negative, var(--c-gray-variant))"></stop></linearGradient></defs>' +
          '<path d="M14.75 1A5.24 5.24 0 0 0 10 4 5.24 5.24 0 0 0 0 6.25C0 11.75 10 19 10 19s10-7.25 10-12.75A5.25 5.25 0 0 0 14.75 1z"></path></svg>';
      }else if((typeof (removeWishlistBtn) != 'undefined'  && (removeWishlistBtn)!=null)){
        removeWishlistBtn.innerHTML = '' +
          '<svg viewBox="0 0 24 24" preserveAspectRatio="none" class="sf-icon-path"> ' +
          '<defs class=""><linearGradient id="linearGradient-10814" x1="0" y1="0" x2="1" y2="0">' +
          '<stop offset="1" stop-color="var(--icon-color)"></stop> ' +
          '<stop offset="0" stop-color="var(--icon-color-negative, var(--c-gray-variant))"></stop></linearGradient></defs>' +
          '<path d="M14.75 1A5.24 5.24 0 0 0 10 4 5.24 5.24 0 0 0 0 6.25C0 11.75 10 19 10 19s10-7.25 10-12.75A5.25 5.25 0 0 0 14.75 1z"></path></svg>';
      }else if((typeof (productWishlistIcon) != 'undefined'  && (productWishlistIcon)!=null)){
        productWishlistIcon.innerHTML= '<svg  width="32" height="32"> ' +


          '<path d="M14.75 1A5.24 5.24 0 0 0 10 4 5.24 5.24 0 0 0 0 6.25C0 11.75 10 19 10 19s10-7.25 10-12.75A5.25 5.25 0 0 0 14.75 1z"  style="fill:#018937;"></path></svg>';
        productWislistBtn.innerHTML='Remove from wishlist'
      }

    } catch (err) {
      error.value.addItem = err;
      Logger.error('useOfflineWishlist/addItem', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const clear = async () => {
    Logger.debug('useOfflineWishlist/clear');

    try {
      loading.value = true;
      error.value.clear = null;
      wishlistStore.$patch((state) => {
        state.wishlist = {};
      });
      let data = {};
      localStorage.setItem('wishlistData', JSON.stringify(data));
    } catch (err) {
      error.value.clear = err;
      Logger.error('useOfflineWishlist/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const afterAddingWishlistItemToCart = (
    { product, cartError }: UseWishlistAfterAddingWishlistItemToCartParams,
  ) => {
    Logger.debug('useOfflineWishlist/afterAddingItemToCart', product);

    if (!isInWishlist({ product })) return;

    try {
      if (cartError?.message) {
      //  console.log('wishlist is in offline',isInWishlist({ product }))
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist_error'),
          message: cartError.message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'Wishlist error',
        });
      } else {
       // console.log('wishlist is in offline',isInWishlist({ product }))
        // eslint-disable-next-line promise/catch-or-return
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          message: app.i18n.t(
            'You added {product} to your shopping cart.',
            { product: product.name },
          ) as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Favorites',
        });
      }
    } catch (err) {
      error.value.afterAddingWishlistItemToCart = err;
      Logger.error('useOfflineWishlist/afterAddingWishlistItemToCart', err);
   //   console.log('wishlist is in offline',isInWishlist({ product }))
    }

    isInWishlist({ product });
  };

  const addOrRemoveItem = async ({ product, customQuery }: UseWishlistAddItemParams) => {
    await (isInWishlist({ product }) ? removeItem({ product, customQuery }) : addItem({ product, customQuery }));
  };

  return {
    loadItemsCount,
    isInWishlist,
    addItem,
    load,
    removeItem,
    clear,
    setWishlist,
    afterAddingWishlistItemToCart,
    addOrRemoveItem,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useOfflineWishlist;
export * from './useOfflineWishlist';
