import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery } from '~/types/core';
import {loadOfflineCartCommand} from "~/modules/checkout/composables/useOfflineCart/commands/loadOfflineCartCommand";
import {removeItem} from "~/helpers/asyncLocalStorage";
import {cart} from "@vue-storefront/magento-api/lib/api";

export const removeOfflineItemCommand = {
  execute: async (
    context: VsfContext,
    { currentCart, product, customQuery },
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });
    let data = JSON.parse(localStorage.getItem('cartData'));

    // console.log('Item',product.sku);
      let item;
      for(let index in data.cart.items){

        if(data.cart.items[index].product.sku === product.product.sku){

          item=product.product;
          console.log('ItemData',item);


        }
      }


    if (!item) {
      return;

    }
    let count = 0;
    let total = 0;

    let removeIndex = -1;
    if (data && data.cart.items.length) {
      for (let index in data.cart.items) {
        let cartItem = data.cart.items[index];

        if (item.sku == cartItem.product.sku) {

          removeIndex = parseInt(index);


        } else {
          count += parseInt(data.cart.items[index].product.qty);
          total += data.cart.items[index].product.qty * data.cart.items[index].product.price_range.minimum_price.final_price.value;
        }
      }

      data.cart.items.splice(removeIndex, 1);
      let totalQty=0;
      let totalOffline=0;
      let qty=0;
      for(let qtyIndex in data.cart.items){
        qty=data.cart.items[qtyIndex].quantity
        totalQty+=qty;
        totalOffline+=data.cart.items[qtyIndex].quantity * data.cart.items[qtyIndex].product.price_range.minimum_price.final_price.value

      }
      data.cart.total_quantity = totalQty;
      data.cart.prices = {
        applied_taxes: [],
        discounts: null,
        grand_total: {value: totalOffline},
        subtotal_excluding_tax: {value: totalOffline},
        subtotal_including_tax: {value: totalOffline},
      };
      localStorage.setItem('cartData', JSON.stringify(data));
    }

    Logger.debug('[ResultData]:', { data });
   // await loadOfflineCartCommand.execute(context, { customQuery });
    // eslint-disable-next-line consistent-return
    console.log('datafroomremove',data.cart)
    return data.cart as unknown as Cart;
  },
};
