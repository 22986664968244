









































































































import {
  computed, ref, useRoute, defineComponent,
} from '@nuxtjs/composition-api';
import { SfHeading, SfButton, SfCallToAction } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfHeading,
    SfButton,
    SfCallToAction,
  },
  setup(props, context) {
    context.emit('changeStep', 4);
    const route = useRoute();

    const companyDetails = ref({
      name: 'AB DFS',
      street: 'Fiskebäcks hamn 7',
      city: '426 58 Västra Frölunda',
      email: 'demo@vuestorefront.io',
    });
    const orderNumber = computed(() => route.value.query.order ?? '');

    return {
      addBasePath,
      companyDetails,
      orderNumber,
    };
  },
});
