import { defineStore } from 'pinia';
import {CategoryResult, useCategory, useUser} from '~/composables';
import categoryListGql from './graphql/categoryList.gql';
import {Logger} from "~/helpers/logger";
import {entries} from "idb-keyval";

interface CategoryState {
  rawCategories: CategoryResult | null
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
  }),

  actions: {
    async load() {
      const isOffline = () => {


        if (typeof window != 'undefined') {
          if (localStorage.getItem('isOffline') == 'true') {
            return true;
          }
        }
        return false;
      }

      if (!isOffline()){
        const {data}: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({query: categoryListGql});
        // console.log('category list', JSON.stringify(data));

      this.rawCategories = data?.categories ?? null;
        // console.log('rawCategories',JSON.stringify(this.rawCategories ))
        }else{
        let {categories, error, load: loadCategories, loading} = useCategory();
        let data={
          categories: {
            items: [{
              is_anchor: null,
              name: '',
              position: 0,
              product_count: 0,
              uid: '',
              url_path: '',
              url_suffix: '.html',
              include_in_menu: 1,
              __typename: "CategoryTree",
              children: [{
                is_anchor: 1,
                name: '',
                position: 0,
                product_count: 0,
                uid: '',
                url_path: '',
                url_suffix: '.html',
                include_in_menu: 1,
              }]
            }],
          },
            __typename: "CategoryResult"

        };
        entries().then(function (entries) {
          for (const dataOffline of entries) {
            if (dataOffline[0].toString().startsWith('categoryTree')) {
              categories.value = [];
              categories.value[0] = dataOffline[1]["Mg=="];
              // data.items=dataOffline[1]["Mg=="]
              //
              data.categories.items['position']=categories.value[0].level;
              data.categories.items['name']=categories.value[0].name;
              data.categories.items['product_count']=categories.value[0].product_count;
              data.categories.items['uid']=categories.value[0].uid;
              data.categories.items['url_path']=categories.value[0].url_path;
              data.categories.items[0].children[0].name=categories.value[0].children['NTEz'].name;
              data.categories.items[0].children[0].position=categories.value[0].children['NTEz'].product_count;
              data.categories.items[0].children[0].uid=categories.value[0].children['NTEz'].uid;
              data.categories.items[0].children[0].url_path=categories.value[0].children['NTEz'].url_path;



            }
          }
        });
        this.rawCategories=   data?.categories ?? null;;




      }
    },
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

        return state.rawCategories?.items[0];

    },
  },
});
