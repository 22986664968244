import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a3bbd60 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _ed3032a8 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _281738b7 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _3961b566 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _66461e18 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _6a616901 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _3d3ddd6f = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _6d2ade95 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _7fe66faa = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _dc816abe = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _1e0832af = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _05326aac = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _0978a17b = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _35a09ebf = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _6c4a0ce4 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _236e992c = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _73a3aedc = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _47643dd2 = () => interopDefault(import('../pages/Reset.vue' /* webpackChunkName: "pages/Reset" */))
const _10f3c9d4 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _25d6bf2e = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _2a3bbd60,
    name: "checkout___sv_SE",
    children: [{
      path: "billing",
      component: _ed3032a8,
      name: "billing___sv_SE"
    }, {
      path: "payment",
      component: _281738b7,
      name: "payment___sv_SE"
    }, {
      path: "shipping",
      component: _3961b566,
      name: "shipping___sv_SE"
    }, {
      path: "thank-you",
      component: _66461e18,
      name: "thank-you___sv_SE"
    }]
  }, {
    path: "/customer",
    component: _6a616901,
    meta: {"titleLabel":"My Account"},
    name: "customer___sv_SE",
    children: [{
      path: "addresses-details",
      component: _3d3ddd6f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___sv_SE"
    }, {
      path: "my-newsletter",
      component: _6d2ade95,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___sv_SE"
    }, {
      path: "my-profile",
      component: _7fe66faa,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___sv_SE"
    }, {
      path: "my-wishlist",
      component: _dc816abe,
      meta: {"titleLabel":"My favorites"},
      name: "customer-my-wishlist___sv_SE"
    }, {
      path: "order-history",
      component: _1e0832af,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___sv_SE"
    }, {
      path: "/reset-password",
      component: _05326aac,
      alias: "/customer/account/createPassword",
      name: "reset-password___sv_SE"
    }, {
      path: "addresses-details/create",
      component: _0978a17b,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___sv_SE"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35a09ebf,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___sv_SE"
    }, {
      path: "order-history/:orderId",
      component: _6c4a0ce4,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___sv_SE"
    }]
  }, {
    path: "/Home",
    component: _236e992c,
    name: "Home___sv_SE"
  }, {
    path: "/Page",
    component: _73a3aedc,
    name: "Page___sv_SE"
  }, {
    path: "/Reset",
    component: _47643dd2,
    name: "Reset___sv_SE"
  }, {
    path: "/reset/:token",
    component: _47643dd2,
    name: "reset___sv_SE"
  }, {
    path: "/p/:id/:slug",
    component: _10f3c9d4,
    name: "product___sv_SE"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _25d6bf2e,
    name: "category___sv_SE"
  }, {
    path: "/",
    component: _236e992c,
    name: "home___sv_SE"
  }, {
    path: "/:slug+",
    component: _73a3aedc,
    name: "page___sv_SE"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
